.form-option-button {
    padding: 12px 0;
    font-size: 1rem;
    font-weight:600;
    color: black;
    cursor: pointer;
    border: none;
    font-family: "Montserrat", sans-serif;
    background: #ffffff;
    transition: all 50ms ease-in-out;
}

.form-option-button:active, .form-option-button:focus-visible {
    background-color: #6f2232;
    color: #ffffff;
}

.form-option-button--active {
      background-color: #6f2232;
      color: #ffffff;
}

.form-option-button--circle{
  width:44px;
  height:44px;
  border-radius: 50%;
}
  
.form-option-button--rectangle{
  width:100%;
  border-radius: 20px;
}
.option-button--active {
  background-color: #6f2232;
  color: #ffffff;
}

.option-button:active {
  transform: scale(0.98);
}

.option-button {
  grid-column: span 2;
}

.option-button:nth-last-child(1):nth-child(odd) {
  grid-column: 2 / span 2;
}

@media screen and (max-width: 760px) {
  .option-button:nth-last-child(1):nth-child(odd) {
    grid-column: auto;
  }

  .form-option-button {
    padding: 6px 0;
    font-size: 0.9rem;
  }
}