.play {
  border-top: 10px solid white;
  border-bottom: 10px solid white;
  border-left: 20px solid black;
  height: 0px;
}

.playButton {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 10px 30px;
  background-color: white;
  color: hsl(345, 95%, 68%);
  border: none;
  border-radius: 20px;
}

.playIconWrapper {
  position: absolute;
  top: 20px;
  left: 20px;
    width: 60px;
    height: 60px;
  background-color: white;
  padding: 15px;
  border-radius: 50%;
  /* display: none; */
}

.playerIcon {
  width: 30px;
  height: 30px;
  color: black;

}
