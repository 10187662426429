@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap");

// .welcome-container {
//   // display: block;
// }

.welcome-container p {
  font-weight: 200;
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  color: #f5f5f5;
}

 .initial-text-container h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-align: center;
  color: #f5f5f5;
    font-size: 50px;
 }

.initial-text-container {
  width: 100vw;
  height: 100vh;
  background-color: black;
  padding: 30px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
}

.initial-text-container img {
  width: 400px;
  padding: 40px 0px;
}

.initial-text-container p {
  font-size: 40px;
  width: 800px;
  margin: 20px 0px;
  font-weight: 200;
  color: white;
  text-align: center;
}

.bold-text {
  font-weight: 700;
}

.presentation-container {
  width: 100vw;
  height: 100vh;
  // background-color: #1a1a1a;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px 80px;
}

.grid-section {
  width: 700px;
}

.bottom-section {
  display: flex;
  align-items: center;
  // border-top: 2px solid white;
  padding: 30px 30px 30px 0px;
}


.alert-image {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.presentation-container h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 60px;
  font-weight: 700;
  color: #f5f5f5;
}

.presentation-container h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  // width: 80%;
  font-weight: 700;
  color: #f5f5f5;
  margin-bottom: 20px;
}

.presentation-container p {
  font-weight: 200;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  line-height: 25px;
}

.before-start-col {
  display: flex;
  flex-direction: column;
  width: 80%
  // justify-content: center;
}

.content-start-col {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  padding: 30px 0px;
}

.bottom-section p {
  font-size: 15px;
  width: 100%;
  line-height: 1;
}

@media screen and (max-width: 760px) {
  .welcome-container h2 {
    font-size: 40px;
    width: auto;
  }

  .welcome-container p {
    font-size: 40px;
  }

  .before-start-col a {
    position: relative;
    padding: 5px;
  }

  .initial-text-container h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 26px;
    width: auto;
    font-weight: 700;
    text-align: center;
    color: #f5f5f5;
  }

  .initial-text-container p {
    padding: 0px;
    font-size: 26px;
    width: auto;
  }

  .grid-section {
    grid-template-columns: 1fr;
    width: 100%;
  }

  .presentation-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: left;
    padding: 50px 50px;
  }

  .presentation-container h1 {
    font-size: 30px;
  }

  .presentation-container h2 {
    width: auto;
    font-size: 18px;
    padding: 10px 0px;
    margin-bottom: 0px;
  }

  .presentation-container p {
    font-size: 14px;
    line-height: 20px;
    width: 100%;
  }

  .bottom-section {
    display: block;
    padding: 0px;
    border-top: none;
  }

  .bottom-section p {
    font-size: 12px;
  }

  .text-result-wrapper {
    width: auto;
  }

  .buttons-results {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
