.option-button {
    width:100%;
    // max-width: 400px;
    // min-width: 300px;
    padding: 0 30px;
    height: 2.5rem;
    font-size: 1.5rem;
    // font-weight: 700;
    color: black;
    border-radius: 20px;
    cursor: pointer;
    border: none;
    font-family: "Montserrat", sans-serif;
    background: #ffffff;
    transition: all 50ms ease-in-out;
}
  
.option-button--active {
  background-color: #6f2232;
  color: #ffffff;
  
}

.option-button:active {
  transform: scale(0.98);
}

.option-button {
  grid-column: span 2;
}

.option-button:nth-last-child(1):nth-child(odd) {
  grid-column: 2 / span 2;
}

@media screen and (max-width: 760px) {
  .option-button:nth-last-child(1):nth-child(odd) {
    grid-column: auto;
  }

  .option-button {
    height: 2rem;
    font-size: 1.2rem;
    min-width: 200px;
  }
}