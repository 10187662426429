.box-form-container{
    display: flex;
    flex-direction: column;
    justify-content:center;

    background-color:var(--just-black);
    color:var(--just-white);
    width:100vw;
    min-height:100vh;
    padding:0 15px;

    h2{
        width:100%;
        text-align:center;
        padding: 0 0 0 0;
    }
    form{
        min-width:200px;
        max-width:800px;
        margin: 40px auto 40px;
    }
    .form-answers {
        min-width: 200px;
        max-width: 800px;
        margin: 40px auto 40px;
    }
}

@media screen and (max-width: 760px) {

    .box-form-container {
        justify-content:start;
        padding: 30px 15px;
    }

    .box-form-container .form-answers {
        margin: 0px;
    }

    .form-wrapper .form-survey-input {
        width: 300px !important;
    }
    
}