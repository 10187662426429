@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap");

* {

  // color: #ffffff;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh; //se modificó a min-height de height para que tenga elaticidad
  font-family: "Montserrat", sans-serif;
  background-color: black;
}

h2{
  margin: 0 0 20px 0;
}

h3{

  margin: 20px 0;

}
