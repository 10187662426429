.game-container {
  min-height: 200px;
  height: min-content;
  border-radius: 15px;
  // padding: 20px;
  box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.75);
  // background-color: #f5f5f5;
color: #ffffff;
}

.button-wrapper {

  position: relative;
  display: flex;
  justify-content: center;
  width: 600px;
  margin-top: 70px;
}

.navigationButtons {
  border: black;
  background-color: white;
  color: black;
  padding: 10px 20px;
  border-radius: 20px;
  margin: 20px 0px;
color: #ffffff;
}

.timer-text {
  background: rgb(230, 153, 12);
  padding: 15px;
  margin-top: 20px;
  margin-right: 20px;
  border: 5px solid rgb(255, 189, 67);
  border-radius: 15px;
  text-align: center;
}

/* ANSWERS/RIGHT SECTION */
.answer-section {
  display: grid;
	grid-template-columns: repeat( 4, 1fr );  
  grid-gap: 30px;
  margin-top: 3%;
  font-family: "Montserrat", sans-serif;
}

// .gridNow {
//     display: grid;
//     grid-template-columns: repeat(3, 1fr);
//     grid-gap: 30px;
//     margin-top: 3%;
//     font-family: "Montserrat",
//     sans-serif;
// }




.playGameButton {
  width: 400px;
  height: 2.5rem;
  font-size: 1.5rem;
  // font-weight: 700;
  color: black;
  border-radius: 20px;
  cursor: pointer;
  // margin: 5px 0px;
  border: none;
  font-family: "Montserrat", sans-serif;
  background-color: #ffffff;
  outline: none;
  transition: 0.2s all;
}

.playGameButton:active {
  transform: scale(0.98);
}

.playGameButton:hover {
  background-color: whitesmoke;
}


.correct {
  background-color: #2f922f;
}

.incorrect {
  background-color: #ff3333;
}

button svg {
  margin-right: 5px;
}

@media screen and (max-width: 800px) {

  .playGameButton {
    width: 250px;
    height: auto;
    font-size: 1.2rem;
    font-weight: 500;
    color: black;
    border-radius: 20px;
    cursor: pointer;
    border: none;
    font-family: "Montserrat", sans-serif;
    background-color: #ffffff;
    margin-bottom: 5px;
    padding: 3px 0px;
  }

  .answer-section {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }

  .nextButton {
    margin: 20px 0px;
  }

  .initial-text-container img {
    width: 200px;
  }

  .initial-text-container p {
    text-align: center;
  }

  .question-section {
    width: auto;
  }

  .button-wrapper {
    width: 300px;
  }

  .option-button {
	grid-column: 1
}

.option-button:nth-last-child(1):nth-child(odd) {
	grid-column: 1;
}
}
