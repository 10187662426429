.progressbar-container {
  position: absolute;
  top: 10px;
  left: 900px;
  padding: 10px 30px;
  border: none;
  border-radius: 20px;
  width: 200px;
}

/* .circle-counter {
  width: 20%;
} */

.Counter {
  position: absolute;
top: 20px;
right: 20px;
}


.CircularProgressbar .CircularProgressbar-text {
    font-size: 40px !important;
      font-family: "Spartan", sans-serif;
      color: black;
      fill: red;

}

.CircularProgressbar .CircularProgressbar-path {
    stroke: red;
}

.counter-text {
    font-size: 40px;
    padding: 20px;
}