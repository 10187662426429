@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap");

.form-container {
  background-color: #f5f5f5;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-title {
  font-size: 60px;
  color: rgb(27, 24, 24);
  text-align: center;
  font-family: "Montserrat", sans-serif;
}
.form-text {
  font-size: 30px;
  color: rgb(27, 24, 24);
  text-align: center;
  font-weight: 300;
  margin: 20px 0px;
  font-family: "Montserrat", sans-serif;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-wrapper label {
  color: black;
  font-size: 22px;
  font-family: "Montserrat", sans-serif;
  padding: 10px 0px;
}

.survey-label {
  color: white !important; 
}

.form-wrapper input[type="text"],
input[type="email"] {
  border: 1px solid black;
  border-radius: 20px;
  font-family: "Montserrat", sans-serif;
  width: 600px;
  padding: 10px;
  color: black;
}

.form-wrapper .form-survey-input {
    border: 1px solid black;
    border-radius: 20px;
    font-family: "Montserrat",
    sans-serif;
    width: 600px !important;
    padding: 10px;
    color: black;
}

.send-form-button {
  width: 600px;
  font-size: 20px;
  color: #f5f5f5;
  border-radius: 20px;
  padding: 5px;
  cursor: pointer;
  margin: 5px 0px;
  border: none;
  height: 45px;
  font-family: "Montserrat", sans-serif;
  background-color: #050505;
  margin: 20px 0px;
}

.send-form-button-survey {
  background-color: #f5f5f5 !important;
    color:#050505 !important;
}

.send-form-button:active {
    transform: scale(0.98);
}


.privacy-checkbox {
  display: flex;
}
.validation-Error {
  color: crimson;
  padding: 10px;
}

.thank-you-message {
  color: green;
  font-size: 15px;
}





@media screen and (max-width: 760px) {
  // .form-wrapper input[type="text"],
  .share-modal-container input,
  input[type="email"] {
    width: auto;
  }


  .form-text {
    font-size: 18px;
  }

  .send-form-button {
    width: 200px;
  }

  .swal2-container {
    //retiré esto porque interfería con los alerts
    // margin: 15px;
  }

  .form-h3-survey {
    text-align: center;
    font-size: 16px;
    padding: 0px 30px;
  }
}
