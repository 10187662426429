.aboutUs-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.aboutUs-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutUs-title {
  font-family: "Montserrat", sans-serif;
  font-size: 60px;
  width: 500px;
  font-weight: 700;
  text-align: center;
  color: #f5f5f5;
}
.aboutUs-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutUs-text {
  font-weight: 200;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  width: 350px;
  padding: 0px 0px 0px 40px;
  color: #f5f5f5;
}


.aboutUs-image {
  width: 350px;
  padding: 1rem;
  display: flex;
}
.aboutUs-image-vid {
  width: 150px;
  padding: 2rem;
  display: flex;
  margin-bottom: -10px;
  justify-content: flex-end;
}

.aboutUs-loader {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-top: 30px;
  text-align: center;
  color: #f5f5f5;
}

.button-about {
    border: none !important;
}

.swal2-styled.swal2-confirm {
  background-color: black;
  border: none !important;
  border-radius: 20px;
  width: 250px;
  height: 45px;
}

@media screen and (max-width: 768px) {

  .aboutUs-wrapper {

    flex-direction: column;
    padding: 30px;

  }

  .aboutUs-container {
    display: block;
    min-height: 100vh;
    padding: 30px;
  }

  .aboutUs-title {
    font-size: 30px;
    width: auto;
  }

  .aboutUs-image {
    width: 200px;
  }

  .aboutUs-text {
    font-size: 16px;
  }

 .aboutUs-loader {
padding-bottom: 30px;
    margin-top: 10px;

  }
  
}