.form-wrapper-game {
    display: flex;
    justify-content: center;
}

// .form-wrapper-game input[type=text],
// input[type=email] {
//     border: 1px solid black;
//     border-radius: 20px;
//     font-family: "Montserrat",
//         sans-serif;
//     width: 600px;
//     padding: 10px;
//     color: black;
//     background-color: #f5f5f5;
//     outline: none;
//     transition: 0.2s all;
//     height: 2.5rem;
//     font-size: 1rem;
//     margin-right: 20px;
// }

    .form-wrapper-game .form-game-input { 
    border: 1px solid black;
    border-radius: 20px;
    font-family: "Montserrat",
    sans-serif;
    width: 200px;
    padding: 10px;
    color: black;
    background-color: #f5f5f5;
    outline: none;
    transition: 0.2s all;
    height: 2.5rem;
    font-size: 1rem;
    margin-right: 20px;
    }

.form-text {
    color: white;
}

.getResultButton {
    width: 200px;
    height: 2.5rem;
    font-size: 1rem;
    // font-weight: 700;
    color: black;
    border-radius: 20px;
    cursor: pointer;
    border: none;
    font-family: "Montserrat",
        sans-serif;
    background-color: #ffffff;
    outline: none;
    transition: 0.2s all;
}

@media screen and (max-width: 760px) {
    .form-wrapper-game {
        flex-direction: column;
        align-items: center;
    }

    // .form-wrapper-game input[type="text"],
    // input[type="email"] {
    //     margin-right: 0px;
    //     margin-bottom: 10px;
    // }

    .form-wrapper-game .form-game-input {
        width: auto;
                margin-right: 0px;
                margin-bottom: 10px;
    }

    .form-text {
     padding: 0px 40px;
    }
}