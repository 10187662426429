.item-container{
    display:grid;
    grid-template-columns: repeat(auto-fill, 160px);
    justify-content:center;
    gap:20px;
    width: 100%;
}

.grid-colums-3 {
    grid-template-columns: repeat(auto-fill, 130px);
}

.grid-colums-5{
    grid-template-columns: repeat(auto-fill, 25px);
    gap: 30px;
}

@media screen and (max-width: 760px) { 

// .item-container {
//     gap: 15px;
// }

}