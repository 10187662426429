.results-title {
    font-family: "Montserrat", sans-serif;
    font-size: 60px;
    width: 500px;
    font-weight: 700;
    color: #f5f5f5;
}

.results-subtitle {
    font-family: "Montserrat",
        sans-serif;
    font-size: 28px;
    width: 500px;
    font-weight: 400;
    color: #f5f5f5;
    text-align: left;
}

.results-text-container {
    padding: 0px 0px 0px 40px;
    width: 550px;
}

.results-text {
    font-weight: 200;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    // width: 350px;
    color: #f5f5f5;
    text-align: left;
}

.results-invite {
        color: #f5f5f5;
    font-size: 14px;
    font-weight: 700;
    padding-top: 20px;
    font-family: "Montserrat",
        sans-serif;
            text-align: left;
            line-height: 1.5;
}

@media screen and (max-width: 760px) {
.results-text-container {
    padding: 0px;
    width: 300px;
}
}
