
// .outOfTime-background {
//   width: 100vw;
//   height: 100vh;
//   background-color: #f5f5f5;
// }

.outOfTime-container {
  background-color: #f5f5f5;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.outOfTime-title {
  font-size: 80px;
  color: rgb(27, 24, 24);
  text-align: center;
  font-family: "Montserrat", sans-serif;
}
.outOfTime-text {
  font-size: 40px;
  color: rgb(27, 24, 24);
  text-align: center;
  margin-top: 5%;
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
}

@media screen and (max-width: 760px) {

  .outOfTime-title {
  font-size: 40px;
}
.outOfTime-text {
  font-size: 20px;
}
}
